export default class Fingerprint {
    constructor() {
        this.fpPromise = import('@fingerprintjs/fingerprintjs').then(FingerprintJS => FingerprintJS.load());
    }

    get() {
        return this.fpPromise.then(fp => fp.get())
            .then(result => {
                document.querySelectorAll('.finger_print_js').forEach((el) => {
                    el.value = result.visitorId;
                });
                return result.visitorId;
            });
    }
}
window.Fingerprint = Fingerprint;